<template>
	<div>
		<a-modal title="选择服务项目" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div class="flex center">
						<a-input-search placeholder="搜索服务项目名称" v-model="search.name" style="width: 420px" @search="onSearch" />
					</div>
					
					<div class="mt20">
						<div class="flex wrap center" v-if="datas.length>0">
							<div class="choose-project-item flex space" v-for="(item,index) in datas">
								<div class="flex">
									<img  :src="item.cover_img"/>
									<div class="ml10">
										<div class="ft14 ftw400 cl-main text-over4">{{item.name}}</div>
										<div v-if="JSON.stringify(value) !== '{}' && item.project_id==value.project_id">
											<div class="ft12 ftw400  mt5 ">会员价格：¥{{item.price}}</div>
											<div class="flex alcenter">
												<div class="ft12 ftw400   ">市场价{{value.sku_id}}：¥{{item.market_price}}</div>
												<template v-if="value.sku_id>0">
													<template v-for="(sku_item,sku_index) in item.skus">
														<div class="ml10 ft12 ftw400" v-if="value.sku_id == sku_item.project_sku_id" >(已选规格：{{sku_item.name}})</div>
													</template>
												</template>
											</div>
										</div>
										
										<div v-else>
											<div class="ft12 ftw400  mt5 ">会员价格：¥{{item.price}}</div>
											<div class="flex alcenter">
												<div class="ft12 ftw400   ">市场价：¥{{item.market_price}}</div>
											</div>
										</div>
									</div>
								</div>
								
								<!-- <div class="flex alcenter center" v-if="JSON.stringify(value) !== '{}' && item.project_id==value.project_id">
									<div class="choose-project-item-duihao  flex center alcenter cl-theme">
										<i class="iconfont iconduihao ft12"/>
									</div>
								</div> -->
								<div class="flex alcenter center" v-if="item.skus.length==0">
									<div class="choose-project-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
										选择
									</div>
								</div>
								<div class="flex alcenter center" v-else>
									<div style="width: 80px;" class="choose-project-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="chooseSku(index)">
										选择规格
									</div>
								</div>
							</div>
						</div>
						
						<div v-else class="flex center mt20">
							<a-empty/>
						</div>
					</div>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
					<div v-if="chooseSkuVisible">
						<choose-sku v-model="value" :project="project" :visible="chooseSkuVisible" @cancel="cancelChooseSku" @ok="okChooseSku"></choose-sku>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../../../common/mixin/list.js';
	import chooseSku from './chooseProject/chooseSku.vue';
	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			value:{
				type:Object,
				default:function(){
					return new Object
				},
			},
			shopId:{
			    type:Number,
                default:0,
            }
		},
		mixins:[listMixin],
		components:{
			chooseSku
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				chooseSkuVisible:false,
				pagination:{
					total:0,
					pageSize:4,
					current:1,
				},
				search:{
					name:'',
				},
				datas:[],
				project:{},
				
			}
		},
		
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/chooseProjectFromAppointment',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
                    shopId:this.shopId,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			onSearch(value){
				this.page=1;
				this.getLists()
			},
			
			chooseSku(index){
				this.project=this.datas[index];
				this.chooseSkuVisible=true;
			},
			cancelChooseSku(){
				this.chooseSkuVisible=false;
			},
			okChooseSku(e){
				this.$emit('input',e);
				this.$emit('ok');
				this.chooseSkuVisible=false;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
			
			checkIt(index){
				// if (this.loading == true) return;
				// this.loading = true;
				let value=this.datas[index];
				value.sku_id=0;
				this.$emit('input',value);
				this.$emit('ok');
			}
		}
	}
</script>

<style>
	.choose-project-item{
		width: 420px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 10px;
	}
	
	.choose-project-item img{
		width: 80px;
		height: 60px;
	}
	
	.choose-project-item-duihao{
		width: 60px;
		height: 32px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;
	}
	.choose-project-item-select{
		width: 60px;
		height: 32px;
		background: #4772FF;
		border-radius: 4px;
		cursor: pointer;
	}
</style>
