<template>
	<div>
		<a-modal title="新增预约" :width="540" :visible="visible" @cancel="handleCancel">
			<template slot="footer">
				<a-button key="back" @click="handleCancel">
					取消
				</a-button>
				<a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确认新增
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

						<a-form-model-item  label="选择长者" required>
							<div class="choose-serviceman-box flex  space" style="width: 400px">
								<div class="flex alcenter">
									<img :src="form.oldmember.cover_img" class="choose-serviceman-box-face" />
									<div class="ml10">
										<div class="ft16 ftw600 cl-main text-over4">{{form.oldmember.name}}</div>
										<div class="mt15 flex alcenter">
											<i class="iconfont iconcall_gray mr5 ft12 cl-notice" />
											<div class="cl-info ft12 ftw400 text-over4">{{form.oldmember.mobile}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter cente ft14 ftw600 cl-theme clickAct"
									 @click="oldmemberServiceAct()">
									选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>

            <a-form-model-item label="服务地址" required>
              <a-card size="small" title="" style="width: 400px">
                <a slot="extra" href="#">
                  <span class="ft14 ftw600 mr20 cl-theme" @click="oldmemberAddressSel()">选择<i class="iconfont iconicon_arrowr ft14 ml5" /></span>
                  <span class="ft14 ftw600 cl-theme" @click="oldmemberAddressAdd()">添加<i class="iconfont iconicon_arrowr ft14 ml5" /></span>
                </a>
                <div v-if="JSON.stringify(form.address) != '{}'">
                  <p>
                    <i class="iconfont mr5 ft12 iconmall_group_purchase cl-notice" />联系人：{{form.address.name}}
                    <i class="iconfont iconcall_gray mr5 ml20 ft12 cl-notice" />电话：{{form.address.mobile}}
                  </p>
                  <p>地址：{{form.address.address}} {{form.address.house_address}}</p>
                </div>
              </a-card>
            </a-form-model-item>

						<a-form-model-item v-if="shop_id==0" label="选择站点" required>
							<div style="width: 400px" @click="chooseShopAct()" v-if="JSON.stringify(form.shop) == '{}'"
								 class="choose-project-box ml10 clickAct flex center alcenter cl-theme ft14 ftw600">
								选择<i class="iconfont iconicon_arrowr ft14 ml5" />
							</div>
							<div style="width: 400px" v-else class="choose-project-box flex space">
								<div class="flex alcenter">
									<img :src="form.shop.site_logo" class="choose-serviceman-box-face" />
									<div class="ml10">
										<div class="ft16 ftw600 cl-main text-over4">{{form.shop.name}}</div>
										<div class="mt15 flex alcenter">
											<i class="iconfont iconcall_gray mr5 ft12 cl-notice" />
											<div class="cl-info ft12 ftw400 text-over4">{{form.shop.site_phone}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter cente ft14 ftw600 cl-theme clickAct"
									 @click="chooseShopAct()">
									重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>
						<a-form-model-item v-if="shop_id>0 || JSON.stringify(form.shop) != '{}'" label="选择服务" required>
							<div style="width: 400px" @click="chooseProjectAct()" v-if="JSON.stringify(form.project) == '{}'"
								 class="choose-project-box  clickAct flex center alcenter cl-theme ft14 ftw600">
								选择<i class="iconfont iconicon_arrowr ft14 ml5" />
							</div>

							<div style="width: 400px" v-else class="choose-project-box flex space">
								<div class="flex alcenter">
									<img :src="form.project.cover_img" class="choose-project-box-cover-img" />
									<div class="ml10">
										<div class="ft14 ftw400 cl-main text-over4">{{form.project.name}}</div>
										<div v-if="form.project.sku_id>0">
											<template v-for="(sku_item,sku_index) in form.project.skus">
												<template v-if="sku_item.project_sku_id == form.project.sku_id">
													<div class="ft12 ftw400  mt5 ">会员价格：¥{{sku_item.price}}</div>
													<div class="flex alcenter">
														<div class="ft12 ftw400   mt5">市场价：¥{{sku_item.market_price}}
														</div>
														<div class="ml10 ft12 ftw400">
															(已选规格：{{sku_item.name}})</div>
													</div>
												</template>
											</template>
										</div>
										<div v-else>
											<div class="ft12 ftw400  mt5 ">会员价格：¥{{form.project.price}}</div>
											<div class="flex alcenter">
												<div class="ft12 ftw400   mt5">市场价：¥{{form.project.market_price}}</div>
											</div>
										</div>
									</div>
								</div>

								<div @click="chooseProjectAct()"
									 class="flex alcenter cente ft14 ftw600 cl-theme clickAct">
									重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>
						<a-form-model-item label="选择服务人员"  v-if="JSON.stringify(form.project) != '{}'" required>
							<div style="width: 400px" @click="chooseServicemanAct()" v-if="JSON.stringify(form.serviceman) == '{}'"
								class="choose-serviceman-box ml10 clickAct flex center alcenter cl-theme ft14 ftw600">
								选择<i class="iconfont iconicon_arrowr ft14 ml5" />
							</div>

							<div style="width: 400px" v-else class="choose-serviceman-box flex  space">
								<div class="flex alcenter">
									<img :src="form.serviceman.face" class="choose-serviceman-box-face" />
									<div class="ml10">
										<div class="ft16 ftw600 cl-main text-over4">{{form.serviceman.name}}</div>
										<div class="mt15 flex alcenter">
											<i class="iconfont iconcall_gray mr5 ft12 cl-notice" />
											<div class="cl-info ft12 ftw400 text-over4">{{form.serviceman.mobile}}</div>
										</div>
									</div>
								</div>
								<div class="flex alcenter cente ft14 ftw600 cl-theme clickAct"
									@click="chooseServicemanAct()">
									重新选择<i class="iconfont iconicon_arrowr ft14 ml5" />
								</div>
							</div>
						</a-form-model-item>

            <a-form-model-item label="预约时间" required>
              <a-date-picker valueFormat="YYYY-MM-DD" v-model="form.day" />
              <div class="mt10">
                <select-time-range v-model="form.appoint_time"></select-time-range>
              </div>
            </a-form-model-item>
						
						<extra-form v-model="form.extra"></extra-form>
						<a-form-model-item required label="支付方式" >
							<a-radio-group v-model="form.pay_type" name="pay_type"  :default-value="form.pay_type">
								<a-radio value="weixin">
									微信支付
								</a-radio>
								<a-radio value="offline">
									现金支付
								</a-radio>
								<a-radio  v-if="form.oldmember.if_cyf==1" value="merge">
									混合支付
								</a-radio>
								<a-radio v-if="form.oldmember.if_cyf==1" value="cyfpay">
									重阳分支付
								</a-radio>

							</a-radio-group>
						</a-form-model-item>
						<a-form-model-item required label="是否支付" >
							 <a-switch v-model="form.is_paid==1" @change="changePaid" />
						</a-form-model-item>
						<a-form-model-item label="现金价格" required v-if="form.is_paid==1 && (form.pay_type=='merge'|| form.pay_type=='offline')">
							<a-input-number v-model="form.price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="0" :min="0"
								style="width: 200px;" />
						</a-form-model-item>
						<a-form-model-item label="重阳分支付" required v-if="form.is_paid==1  && (form.pay_type=='merge'|| form.pay_type=='cyfpay')">
							<a-input-number v-model="form.cyf_price"  :formatter="value => `${value}元`" placeholder="请输入" :precision="0" :min="2"
											style="width: 200px;" />
						</a-form-model-item>
					</a-form-model>

					<div v-if="addShopVisible">
						<choose-shop v-model="form.shop" :visible="addShopVisible" @cancel="canceladdShop"
									@ok="okAddShop"></choose-shop>
					</div>
					<div v-if="chooseServicemanVisible">
						<choose-serviceman v-model="form.serviceman" :shop-id="form.shop.shop_id" :project-id="form.project.project_id" :visible="chooseServicemanVisible"
							@cancel="cancelChooseServiceman" @ok="okChooseServiceman"></choose-serviceman>
					</div>
          <!--选择长者信息-->
					<div v-if="oldmemberServiceVisible">
						<oldmember-service v-model="form.oldmember" :user-member-id="form.member.member_id" :visible="oldmemberServiceVisible" @cancel="cancelChooseoldmembe" @ok="okChooseoldmembe"></oldmember-service>
					</div>
          <!--选择长者服务地址-->
          <div v-if="oldmemberAddressSelVisible">
            <oldmember-address v-model="form.address" :elder-id="form.oldmember.id" :visible="oldmemberAddressSelVisible" @cancel="cancelOldmemberAddress" @ok="okOldmemberAddress"></oldmember-address>
          </div>
          <!--添加长者服务地址-->
          <div v-if="oldmemberAddressAddVisible">
            <oldmember-address-add v-model="form.address" :elder-id="form.oldmember.id" :visible="oldmemberAddressAddVisible" @cancel="cancelOldmemberAddressAdd" @ok="okOldmemberAddressAdd"></oldmember-address-add>
          </div>
					<div v-if="chooseProjectVisible">
						<choose-project v-model="form.project" :shop-id="form.shop.shop_id" :visible="chooseProjectVisible"
							@cancel="cancelChooseProject" @ok="okChooseProject"></choose-project>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import addMember from '../../../../mall/components/CustomerToOrder/modal/addMember.vue';
	import selectTimeRange from './addAppointment/selectTimeRange.vue';
	import chooseServiceman from './addAppointment/modal/chooseServiceman.vue';
	import oldmemberService from './addAppointment/modal/oldmemberService.vue';
	import oldmemberAddress from './addAppointment/modal/oldmemberAddress.vue';
	import oldmemberAddressAdd from './addAppointment/modal/oldmemberAddressAdd.vue';
	import chooseProject from './addAppointment/modal/chooseProject.vue';
	import chooseShop from './addAppointment/modal/chooseShop.vue';
	import extraForm from '../../../../../components/extra/form.vue';

	export default {
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			shopId:{
			    type:Number,
                default: 0,
			}
		},
		components: {
			addMember,
      chooseShop,
			selectTimeRange,
			chooseServiceman,
			chooseProject,
			extraForm,
      oldmemberService,
      oldmemberAddress,
      oldmemberAddressAdd,
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				addShopVisible: false,
				showMemberLoading: false,
				chooseServicemanVisible: false,
				chooseProjectVisible: false,
        oldmemberServiceVisible: false,
        oldmemberAddressSelVisible: false,
        oldmemberAddressAddVisible: false,
        showserver:false,
				msgStatus: false,
				labelCol: {
					span: 4
				},
				wrapperCol: {
					span: 20
				},
				search: {
          keyword: '',
				},
				shop_id:0,
				regiondata:[],
				form: {
					member: {},
					oldmember:{},
          address:{},
					serviceman: {},
					project: {},
					day: '',
          shop:{},
          pay_type:'offline',
					appoint_time: [],
					is_paid:0,
					need_pay: '',
          price: '',
          cyf_price: '',
					extra:[]
				}
			}
		},
        created() {

            this.loaddata();
        },
		methods: {
            loaddata(){
                this.shop_id=this.shopId;
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('admin/region',{
                    project_id:this.project_id,
                    shop_id:this.shop_id
                }).then(res=>{
                    this.regiondata=res.lists;
                    this.loading=false;
                    if(res.shop.length>0){
                        this.form.shop=res.shop;
					}
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                    this.$router.go(-1);
                });

        },

			reChooseAct() {
				this.msgStatus = false;
				this.form.member = new Object;
			},

			//选择服务人员
			chooseServicemanAct() {
				this.chooseServicemanVisible = true;
			},
			cancelChooseServiceman() {
				this.chooseServicemanVisible = false;
			},
			okChooseServiceman() {
				this.chooseServicemanVisible = false;
			},
			//选择长者
			oldmemberServiceAct(){
        this.oldmemberServiceVisible=true;
        this.form.address = new Object;
			},
      cancelChooseoldmembe(){
        this.oldmemberServiceVisible=false;
			},
      okChooseoldmembe(){
        this.oldmemberServiceVisible=false;
			},

      //选择长者地址
      oldmemberAddressSel(){
        if(this.form.oldmember.id>0){
          this.oldmemberAddressSelVisible=true;
        }else{
          this.$message.error('请选择用户');
          return false;
        }
      },
      cancelOldmemberAddress(){
        this.oldmemberAddressSelVisible=false;
      },
      okOldmemberAddress(){
        this.oldmemberAddressSelVisible=false;
      },
      //添加长者地址
      oldmemberAddressAdd(){
        if(this.form.oldmember.id>0){
          this.oldmemberAddressAddVisible=true;
        }else{
          this.$message.error('请选择用户');
          return false;
        }
      },
      cancelOldmemberAddressAdd(){
        this.oldmemberAddressAddVisible=false;
      },
      okOldmemberAddressAdd(){
        this.oldmemberAddressAddVisible=false;
      },

			//选择项目
			chooseProjectAct() {
				this.chooseProjectVisible = true;
			},
			cancelChooseProject() {
				this.chooseProjectVisible = false;
			},
			okChooseProject() {
				this.chooseProjectVisible = false;
				this.form.serviceman={};
			},
			//选择站点
      chooseShopAct(){
          this.addShopVisible = true;
			},
      canceladdShop() {
          this.addShopVisible = false;

      },
      okAddShop() {
        this.addShopVisible = false;
				this.form.serviceman={};
				this.form.project={};
      },

			changeAppointTime(value) {
				this.form.appoint_time = value;
			},
			
			changePaid(){
				this.form.is_paid=this.form.is_paid == 1 ? 0 :1;
			},

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

			/**
			 * 提交数据
			 */
			onSubmit() {
        if (JSON.stringify(this.form.oldmember) == '{}') {
          this.$message.error('请选长者');
          return;
        }
				if (JSON.stringify(this.form.project) == '{}') {
					this.$message.error('请选择项目');
					return;
				}

				if (!this.form.project.project_id) {
					this.$message.error('请选择项目');
					return;
				}
				
				if (this.form.project.sku_id<0) {
					this.$message.error('请选择项目');
					return;
				}
				let project=new Array;
				project.push({
					project_id:this.form.project.project_id,
					sku_id:this.form.project.sku_id
				});

				if (JSON.stringify(this.form.serviceman) == '{}') {
					this.$message.error('请选择服务人员');
					return;
				}

				if (!this.form.serviceman.serviceman_id) {
					this.$message.error('请选择服务人员');
					return;
				}

        if (!this.form.address.address_id) {
          this.$message.error('请选服务地址');
          return;
        }

				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/saveAppointment', {
					member_id: this.form.oldmember.member_id,
					project: project,
					serviceman_id: this.form.serviceman.serviceman_id,
					day: this.form.day,
					appoint_time: this.form.appoint_time,
					is_paid:this.form.is_paid,
          pay_type: this.form.pay_type,
          price: this.form.price,
          shop: this.form.shop,
          cyf_price: this.form.cyf_price,
          oldmember_id:this.form.oldmember.id,
          address_id:this.form.address.address_id,
					extra:JSON.stringify(this.form.extra),
				}).then(res => {
					this.$message.success('操作成功');
					this.$emit("ok");
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
		}
	}
</script>

<style>
	.choose-serviceman-box {
		width: 280px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
		line-height: 1;
	}

	.choose-serviceman-box-face {
		width: 60px;
		height: 60px;
		border-radius: 2px;
	}

	.choose-project-box {
		width: 400px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
		line-height: 1;
	}

	.choose-project-box-cover-img {
		width: 80px;
		height: 60px;
	}
</style>
