<template>
    <div>
        <a-modal title="选择站点" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
            <a-spin :spinning="loading">
                <div>
                    <div class="flex center">
                        <a-input-search placeholder="搜索站点" v-model="search.name" style="width: 420px" @search="onSearch" />
                    </div>

                    <div class="mt20">
                        <div class="flex wrap center" v-if="datas.length>0">
                            <div class="choose-serviceman-item flex space" v-for="(item,index) in datas">
                                <div class="flex">
                                    <img  :src="item.site_logo"/>
                                    <div class="ml10">
                                        <div class="ft16 ftw600 cl-main text-over4">{{item.name}}</div>
                                        <div class="mt15 flex alcenter">
                                            <i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
                                            <div class="cl-info ft12 ftw400 text-over4">{{item.site_phone}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex alcenter center" v-if="JSON.stringify(value) !== '{}' && item.shop_id==value.shop_id">
                                    <div class="choose-serviceman-item-duihao  flex center alcenter cl-theme">
                                        <i class="iconfont iconduihao ft12"/>
                                    </div>
                                </div>
                                <div class="flex alcenter center" v-else>
                                    <div class="choose-serviceman-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
                                        选择
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="flex center mt20">
                            <a-empty/>
                        </div>
                    </div>

                    <div class="flex center mt20">
                        <a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
    import {listMixin} from '../../../../../../../common/mixin/list.js';
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            value:{
                type:Object,
                default:function(){
                    return new Object
                },
            },

        },
        mixins:[listMixin],
        data() {
            return {
                loading: false,
                confirmLoading: false,
                pagination:{
                    total:0,
                    pageSize:4,
                    current:1,
                },
                search:{
                    name:'',
                },
                datas:[],
                // project_id:this.projectId
            }
        },

        methods: {

            getLists(){

                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('admin/chooseShopFromAppointment',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    this.loading=false;
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                })
            },

            onSearch(value){
                this.page=1;
                this.getLists()
            },

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },

            checkIt(index){
                if (this.loading == true) return;
                this.loading = true;
                let value=this.datas[index];
                this.$emit('input',value);
                this.$emit('ok');
            }
        }
    }
</script>

<style>
    .choose-serviceman-item{
        width: 420px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EBEDF5;
        margin-bottom: 10px;
        padding: 10px;
    }

    .choose-serviceman-item img{
        width: 60px;
        height: 60px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
        border-radius: 40px;
        border: 2px solid #FFFFFF;
    }

    .choose-serviceman-item-duihao{
        width: 60px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #4772FF;
    }
    .choose-serviceman-item-select{
        width: 60px;
        height: 32px;
        background: #4772FF;
        border-radius: 4px;
        cursor: pointer;
    }
</style>
