<template>
	<div>
		<a-modal title="添加长者服务地址" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					添加
				</a-button>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex  mt10">
						<a-input class="woyaou-input" style="width: 230px;" prefix="联系人" placeholder="请输入联系人" v-model="form.name" :maxLength="20">
						</a-input>
						<a-input class="woyaou-input ml20" prefix="手机号" style="width: 230px;" placeholder="请输入联系人手机号" v-model="form.mobile" :maxLength="20">
						</a-input>
					</div>
          <div class="mt10">
            <div class="flex alcenter">
              <a-input class="woyaou-input" v-model="form.address" style="width: 415px;color: #2E2F33;" prefix="地址" placeholder="请选择服务地址" disabled></a-input>
              <baidu-map :lat="home_lat" :lng="home_lng" :keyword1="form.address" @select="selectAct"></baidu-map>
            </div>
            <div class="flex alcenter mt5">
              <a-input class="woyaou-input" style="width: 430px;color: #2E2F33;" prefix="经纬度" :value="home_lat + ',' + home_lng" disabled></a-input>
            </div>
          </div>
					<div class="mt10">
            <a-input class="woyaou-input" style="width: 480px;" prefix="门牌号" placeholder="请输入服务门牌号" v-model="form.house_address"></a-input>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
  import BaiduMap from '../../../../../../../components/map/baidumap.vue';
	export default {
    components:{
      BaiduMap
    },
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      value:{
        type:Object,
        default:function(){
          return new Object
        },
      },
      elderId:{},
    },
		data() {
			return {
				loading: false,
				confirmLoading: false,
        home_lat:0,
        home_lng:0,
				form: {
					name:'',
					mobile:'',
          address:'',
          house_address:'',
          elder_id:this.elderId,
          home_lat:0,
          home_lng:0,
				}
			}
		},
		methods: {
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.form.name == ''){
					this.$message.error('请输入联系人');
					return false;
				}

        if(this.form.address == ''){
          this.$message.error('请输入选择服务地址');
          return false;
        }
				
				if(this.form.mobile == ''){
					this.$message.error('请输入联系人手机号');
					return false;
				}
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveElderAddress',{
          params:this.form
				}).then(res=>{
					this.$message.success('添加成功',1,()=>{
						this.confirmLoading=false;
            let value=this.form;
            value.address_id = res.address_id;
            this.$emit('input',value);
						this.$emit("ok");
					})
					
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},

      selectAct(e){
        this.form.address = e.keyword;
        this.form.home_lat = e.lat;
        this.form.home_lng = e.lng;
        this.home_lat = e.lat;
        this.home_lng = e.lng;
      },
			
		}
	}
</script>

<style>
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
</style>
