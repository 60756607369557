import { render, staticRenderFns } from "./chooseSku.vue?vue&type=template&id=4b85fc44&"
import script from "./chooseSku.vue?vue&type=script&lang=js&"
export * from "./chooseSku.vue?vue&type=script&lang=js&"
import style0 from "./chooseSku.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports