<template>
    <div>
        <a-modal title="选择长者服务地址" :width="480" :visible="visible" @cancel="handleCancel" :footer="null">
            <a-spin :spinning="loading">
                <div>
                    <div class="mt10">
                        <div class="flex wrap center" v-if="datas.length>0">
                            <div class="choose-serviceman-item flex space" v-for="(item,index) in datas">
                                <div class="flex">
                                    <div class="ml10">
                                        <div class="mt5 flex alcenter">
                                          <i class="iconfont mr5 ft12 iconmall_group_purchase cl-notice" />联系人：{{item.name}}
                                          <i class="iconfont iconcall_gray mr5 ml20 ft12 cl-notice" />电话：{{item.mobile}}
                                        </div>
                                        <div class="mt5 flex alcenter">
                                          <i class="iconfont iconcall_gray mr5 ft12 cl-notice"/>
                                          <div class="cl-info ft12 ftw400 text-over4">{{item.address}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex alcenter center" v-if="JSON.stringify(value) !== '{}' && item.address_id==value.address_id">
                                    <div class="choose-serviceman-item-duihao  flex center alcenter cl-theme">
                                        <i class="iconfont iconduihao ft12"/>
                                    </div>
                                </div>
                                <div class="flex alcenter center" v-else>
                                    <div class="choose-serviceman-item-select  flex center alcenter cl-w ft14 ftw600 clickAct" @click="checkIt(index)">
                                        选择
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-else class="flex center mt20">
                            <a-empty/>
                        </div>
                    </div>

                    <div class="flex center mt20">
                        <a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
    import {listMixin} from '../../../../../../../common/mixin/list.js';
    export default {
        props: {
          visible: {
              type: Boolean,
              default: false,
          },
          value:{
              type:Object,
              default:function(){
                  return new Object
              },
          },
          elderId:{},
        },
        mixins:[listMixin],
        data() {
            return {
              loading: false,
              confirmLoading: false,
              pagination:{
                  total:0,
                  pageSize:4,
                  current:1,
              },
              datas:[],
              elder_id:this.elderId,
              home_lat:0,
              home_lng:0,
            }
        },

        methods: {

            getLists(){

                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('admin/getElderAddress',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    elder_id:this.elder_id
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.list;
                    this.loading=false;
                }).catch(res=>{
                    console.log(res);
                    this.loading=false;
                })
            },
            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },
            checkIt(index){
                if (this.loading == true) return;
                this.loading = true;
                let value=this.datas[index];
                this.$emit('input',value);
                this.$emit('ok');
            }
        }
    }
</script>

<style>
    .choose-serviceman-item{
        width: 420px;
        height: 80px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #EBEDF5;
        margin-bottom: 10px;
        padding: 10px;
    }

    .choose-serviceman-item img{
        width: 60px;
        height: 60px;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
        border-radius: 40px;
        border: 2px solid #FFFFFF;
    }

    .choose-serviceman-item-duihao{
        width: 60px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #4772FF;
    }
    .choose-serviceman-item-select{
        width: 60px;
        height: 32px;
        background: #4772FF;
        border-radius: 4px;
        cursor: pointer;
    }
</style>
