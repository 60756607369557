<template>
	<div>
		<template  v-for="(item,index) in myPlugins">
			<a-form-model-item  :label="item.valueName" v-if="item.key=='input'">
				<a-input :placeholder="item.parameter.placeholder"   v-model="item.value"></a-input>
			</a-form-model-item>
			
			<a-form-model-item  :label="item.valueName" v-if="item.key=='textarea'">
				<a-textarea   :placeholder="item.parameter.placeholder" :rows="item.parameter.autosize" v-model="item.value"></a-textarea>
			</a-form-model-item>
			
			<a-form-model-item  :label="item.valueName" v-if="item.key=='radio'">
				<a-radio-group v-model="item.value">
					<a-radio :value="item1.name" v-for="(item1,index1) in item.datas">
						{{item1.name}}
					</a-radio>
				
				</a-radio-group>
			</a-form-model-item>
			
			<a-form-model-item  :label="item.valueName" v-if="item.key=='checkbox'">
				<a-checkbox-group v-model="item.value">
					<a-checkbox :value="item1.name" v-for="(item1,index1) in item.datas">
					   {{item1.name}}
					  </a-checkbox>
				</a-checkbox-group>
			</a-form-model-item>
			
			<a-form-model-item  :label="item.valueName" v-if="item.key=='select'">
				<a-select style="width:200px" v-model="item.value">
					<a-select-option :value="index1" v-for="(item1,index1) in item.datas" :key="index1">
					    {{item1.name}}
					</a-select-option>
				</a-select>
			</a-form-model-item>
			
			<a-form-model-item  :label="item.valueName" v-if="item.key=='switch'">
				 <a-switch   v-model="item.value"/>
			</a-form-model-item>
		</template>
		
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Array,
				default: function() {
					return new Array;
				}
			},
			identifier:{
				type:String,
				default:'appointment',
			}
		},
		watch: {
			myPlugins: {
				handler(newName, oldName) {
					console.log(this.myPlugins);
					this.$emit('input', this.myPlugins);
				},
				immediate: false,
				deep: true
			},
			value: {
				handler(newName, oldName) {
					if (this.value != this.myPlugins) {
						this.myPlugins = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data() {
			return {
				myPlugins: [],
			}
		},
		created() {
			this.myPlugins = this.value;
			this.loaddata();
		},
		methods: {
			loaddata() {
				this.$http.api('admin/getExtraFormBak', {
					
				}).then(res => {
					this.myPlugins = res.datas;
				}).catch(res => {
	
				})
			},
		}
	}
</script>

<style>
</style>
